var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-setup-container" },
    [
      _c(
        "b-form-select",
        {
          staticClass: "email-client-select",
          model: {
            value: _vm.selectedClient,
            callback: function ($$v) {
              _vm.selectedClient = $$v
            },
            expression: "selectedClient",
          },
        },
        _vm._l(_vm.emailClients, function (option) {
          return _c(
            "option",
            { key: option.name, domProps: { value: option.value } },
            [_c("label", [_vm._v(_vm._s(option.name))])]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "client-instructions pt-4" },
        [
          _vm.selectedClient === "apple-iphone-ipad"
            ? [
                _c("p", { staticClass: "instructions-client-name" }, [
                  _vm._v("\n        Apple Devices (iPhone/iPad):\n      "),
                ]),
                _vm._m(0),
                _vm._m(1),
              ]
            : _vm.selectedClient === "apple-mac-os"
            ? [
                _c("p", { staticClass: "instructions-client-name" }, [
                  _vm._v(
                    "\n        Apple Mail (macOS - current version: Ventura 13):\n      "
                  ),
                ]),
                _vm._m(2),
                _vm._m(3),
              ]
            : _vm.selectedClient === "gmail"
            ? [
                _c("p", { staticClass: "instructions-client-name" }, [
                  _vm._v("\n        Gmail:\n      "),
                ]),
                _c("p", [
                  _vm._v(
                    '\n        You can receive an email from another account on Gmail by adding that email address as\n        an alias or a "Send mail as" address.\n      '
                  ),
                ]),
                _vm._m(4),
                _c("p", [
                  _vm._v(
                    "\n        Once the email address is verified, you can start receiving email from that address in\n        your primary Gmail inbox. You can also set up the account to forward all incoming emails\n        to your primary account by going to the settings of the account you want to forward from\n        and setting up the forward there.\n      "
                  ),
                ]),
                _vm._m(5),
              ]
            : _vm.selectedClient === "outlook-webmail"
            ? [
                _c("p", { staticClass: "instructions-client-name" }, [
                  _vm._v("\n        Outlook (Webmail Version):\n      "),
                ]),
                _vm._m(6),
                _vm._m(7),
              ]
            : _vm.selectedClient === "outlook-desktop"
            ? [
                _c("p", { staticClass: "instructions-client-name" }, [
                  _vm._v("\n        Outlook (Desktop Version):\n      "),
                ]),
                _vm._m(8),
                _vm._m(9),
              ]
            : _vm._e(),
          _c("p", [
            _vm._v(
              "Once your email is set up, we’ll check that you’re receiving emails."
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "\n          Go to Settings > Mail, then tap Accounts.\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Tap Add Account, tap Other, then tap Add Mail Account.\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Enter your name, email address, password, and a description for your account.\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Tap Next. Mail will try to find the email settings and finish your account setup.\n          If Mail finds your email settings, tap Done to complete your account setup.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        Issues setting up your email? Visit\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "https://support.apple.com/en-us/102619",
            target: "_blank",
          },
        },
        [_vm._v("\n          Apple's Add Email Accounts\n        ")]
      ),
      _vm._v("\n        page for help.\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v("\n          In Mail, choose Mail > Add Account.\n        "),
      ]),
      _c("li", [_vm._v("\n          Select an account type.\n        ")]),
      _c("li", [
        _vm._v("\n          Enter your account information.\n        "),
      ]),
      _c("li", [
        _vm._v(
          "\n          Make sure the Mail checkbox is selected for the account\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Add in the incoming/outgoing server info. Ensure that the email/password is added for\n          BOTH the outgoing and incoming server sections.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        Issues setting up your email? Visit the\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "https://support.apple.com/guide/mail/add-email-accounts-mail35803/mac#:~:text=In%20Mail%2C%20choose%20Mail%20%3E%20Add,use%20that%20account%20with%20Mail",
            target: "_blank",
          },
        },
        [_vm._v("\n          Apple Mail User Guide\n        ")]
      ),
      _vm._v("\n        for help.\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("\n          Open Gmail.\n        ")]),
      _c("li", [
        _vm._v(
          '\n          Click on the gear icon in the top right corner and select "Settings."\n        '
        ),
      ]),
      _c("li", [_vm._v("\n          Click ‘see all settings'.\n        ")]),
      _c("li", [
        _vm._v('\n          Click on the "Accounts and Import" tab.\n        '),
      ]),
      _c("li", [_vm._v("\n          Click on “Add a mail account”\n        ")]),
      _c("li", [
        _vm._v(
          '\n          Enter the email address that you want to send email from and click "Next."\n        '
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Click on “Import emails from my other account (POP3)” and click “Next”.\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Fill out the email and password information\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Use the Outgoing POP server settings below.\n        "
        ),
      ]),
      _c("li", [_vm._v("\n          Click “Add Account.”\n        ")]),
      _c("li", [
        _vm._v(
          "\n          Under “Your mail account has been added” click “Yes, I want to be able to send mail as”, click “Next”.\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Under “Add another email address you own” uncheck “Treat as Alias”\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Follow the prompts to verify the email address.\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Use the Incoming POP server settings below.\n        "
        ),
      ]),
      _c("li", [_vm._v("\n          Click “Add Account.”\n        ")]),
      _c("li", [
        _vm._v(
          "\n          Under “Accounts and import” click “verify” this will send an email verification link you need to click on to be able to use your email account.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        Issues setting up your email? Visit the\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "https://support.google.com/mail/answer/56256?hl=en",
            target: "_blank",
          },
        },
        [_vm._v("\n          Gmail Help Center\n        ")]
      ),
      _vm._v("\n        for help.\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v(
          "\n          Click on the gear icon on the upper right part of the mailbox page and choose View all\n          Outlook settings\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Choose the Sync email option, and click Other email accounts\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Select Manually configure account settings and click OK\n        "
        ),
      ]),
      _c("li", [
        _vm._v(
          "\n          Select type of the account IMAP/SMTP or POP3/SMTP and click OK (IMAP/SMTP is recommend)\n        "
        ),
      ]),
      _c("li", [
        _vm._v("\n          Enter Incoming/Outgoing Server info\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        Issues setting up your email? Visit the\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "https://support.microsoft.com/en-us/office/make-outlook-the-default-program-for-email-contacts-and-calendar-ff7990c4-54c4-4390-8fe3-c0285226f021",
            target: "_blank",
          },
        },
        [_vm._v("\n          Microsoft Outlook Support\n        ")]
      ),
      _vm._v("\n        page for help.\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [
        _vm._v("\n          Open Outlook on your computer.\n        "),
      ]),
      _c("li", [
        _vm._v(
          '\n          Click on the "File" tab in the top left corner of the screen.\n        '
        ),
      ]),
      _c("li", [
        _vm._v(
          '\n          From the dropdown menu, select "Add Account."\n        '
        ),
      ]),
      _c("li", [
        _vm._v(
          '\n          In the Add Account window, enter your email address and click "Connect."\n        '
        ),
      ]),
      _c("li", [
        _vm._v(
          '\n          Select "IMAP" or "POP" as the account type. IMAP is recommended as it allows you to\n          access your emails on multiple devices\n        '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        Issues setting up your email? Visit the\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "https://support.microsoft.com/en-us/office/make-outlook-the-default-program-for-email-contacts-and-calendar-ff7990c4-54c4-4390-8fe3-c0285226f021",
            target: "_blank",
          },
        },
        [_vm._v("\n          Microsoft Outlook Support\n        ")]
      ),
      _vm._v("\n        page for help.\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }